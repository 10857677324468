<template>
  <!--
    The view for the Customer Worklog Report generation
  -->
  <CustomerWorklogReportPdf
    v-if="pdf == 'true'"
    :s-master-component="sMasterComponent"
    :s-component="sComponent"
    :s-start-date="sStartDate"
    :s-end-date="sEndDate"
    :only-tasks="onlyTasks === 'true'"
  />
  <CustomerWorklogReport
    v-else
    :s-master-component="sMasterComponent"
    :s-component="sComponent"
    :s-start-date="sStartDate"
    :s-end-date="sEndDate"
  />
</template>

<script>
export default {
  name: "CustomerWorklogReportView",
  components: {
    CustomerWorklogReport: () =>
      import("@/components/CustomerWorklog/CustomerWorklogReport.vue"),
    CustomerWorklogReportPdf: () =>
      import("@/components/CustomerWorklog/CustomerWorklogReportPdf.vue"),
  },
  props: {
    sMasterComponent: {
      type: String,
      required: false,
      default: null,
    },
    sComponent: {
      type: String,
      required: false,
      default: null,
    },
    sStartDate: {
      type: String,
      required: false,
      default: null,
    },
    sEndDate: {
      type: String,
      required: false,
      default: null,
    },
    pdf: {
      type: String,
      required: false,
      default: null,
    },
    onlyTasks: {
      type: String,
      required: false,
      default: null,
    },
  },
  metaInfo () {
    return {
      title: this.$t("customerWorklogReport"),
    };
  },
};
</script>
